import * as React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';

const KennelTours = () => {
  var pageTitle = 'Kennel Tours';

  return (
    <Layout
      slug="kennel-tours"
      title={pageTitle}
      description="Take a virtual tour or learn more about how to book a tour of our facility!"
    >
      <h1>{pageTitle}</h1>

      <h2>Learn more!</h2>
      <p>
        Learn about our facility, services, safety features and more in this
        video!
      </p>
      <div className="video-container mb-4">
        <iframe
          title="Learn about VIP"
          src="https://www.youtube.com/embed/XTObZvXhf0E"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>

      <h2>Aerial View of Very Important Pets Play Yards</h2>
      <p>
        This video shows an aerial view of one of our play yards. The video was
        taken in the summer of 2019.
      </p>
      <div className="video-container mb-4">
        <iframe
          title="VIP Yard view"
          src="https://www.youtube.com/embed/8mCfYwDK1uQ"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
      <div></div>

      <span className="h2 text-center block">
        More video tours coming soon!
      </span>

      <h2>In Person Tours</h2>
      <p>
        Very Important Pets also offers in person tours at our boarding kennel.
        We encourage you to bring your pet to the in person tour so they are
        able to explore the facility and meet the staff. This helps them to
        settle in and relax during their first stay with us. In person tours are
        also a great opportunity for you to see the various kennel suite options
        we have available as well as.
      </p>
      <p>
        If you want to learn more about our facilities or book an in person tour
        please complete the form on the <Link to="/contact/">contact us</Link>{' '}
        page.
      </p>
    </Layout>
  );
};

export default KennelTours;
